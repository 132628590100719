
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Cards from "@/components/base/common/Cards.vue";
import PolicyCard from "@/views/policy/PolicyCard.vue";
import Entities from "@/components/base/common/Entities.vue";

export default defineComponent({
  name: "IntegrationSearch",
  components: {Entities, PolicyCard, Cards},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Policy Remote Search',
        [
          {link: false, router: '', text: 'Policy Remote Search'}
        ]
      )
    })
    const filterObjects = ref({certificate: '', number: '', firstName: '', lastName: ''})
    const page = computed(() => store.state.PolicyIntegrationModule.page)
    return {
      ...LoadFilterObjects(Actions.LOAD_POLICY_INTEGRATION_SEARCH, filterObjects.value, ['client', 'insureds', 'product']),
      filterObjects,
      page,
    }
  }
})
